.route-loader {
    position: fixed;
    height: 100%;
    width: 100%;
    left: calc(50% - 40px);
    top: calc(50% - 40px);
    max-width: 100px;
    max-height: 100px;
    z-index: 5;
  }
  
 .route-loader {
    left: calc(50% + 75px);
  }
  .sw-chk-with-label{
    height: 18px;
    width: 18px;
  }
  .sw-label-with-chk{
    line-height: 28px;
    padding-left: 0;
  }
 input[readonly]{
  background-color: var(--bs-secondary-bg);
  opacity: 1;
  pointer-events: none;
 }
 .sw-nav-item-link.active::after{
  content: "\f0d7";
  font-family: "Font Awesome 6 Free";
  position: absolute;
  bottom: -3px;
  z-index: 1;
  left: calc(50% - 5px);
  font-size: 14px;
  color: #e3e3e3;
 }
