body {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  background: #f1f1f1;
}

.Graident {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #2c475a;
}

.login-section {
  background: #fff;
  border-radius: 8px;
  max-width: 440px;
  margin: 0px auto;
}

.login-section .form-check {
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}

.login-section .form-check label {
  font-size: 14px;
}

.login-section img {
  max-width: 280px;
}

.login-section .form-group input {
  border-radius: 5px;
  font-size: 14px;
  color: #000;
  height: 42px;
  padding: 0px 0 0 10px;
}

.login-section .form-group input:focus {
  box-shadow: none;
  border-color: #3d596e;
}

.login-section .form-check-input[type="checkbox"] {
  border-radius: 0px;
  border: #3d596e 1px solid;
}

.login-section .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  margin-right: 8px;
}

.login-section .form-check-input:focus {
  box-shadow: none;
}

.login-section .lg-btn {
  padding: 0px;
  margin: 0px 0 0 0;
  font-size: 15px;
  padding: 10px 24px 10px 24px;
  background: #3d596e;
  border: 0px;
  width: 100%;
}

.login-section .lg-btn:hover {
  background: #000;
}

.login-section a.text-primary {
  color: #3d596e !important;
}

.login-section a.text-primary:hover {
  color: #000 !important;
}

.Forgot-btn:hover {
  color: #33c6cb !important;
}

.login-section .form-check-input:checked {
  background-color: #33c6cb;
  box-shadow: none;
}

.profile-img {
  height: 25px;
  width: 25px;
  border-radius: 100%;
}

.nav-acc-dropdown {
  border: 1px solid #5b5b5b;
  border-radius: 4px;
  min-width: 170px;
  padding: 6px;
  background: #fff;
  z-index: 1;
}

.nav-acc-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
}

.nav-acc-dropdown .dropdown-toggle::after {
  content: "\f078";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  content: "\f078";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  border: 0;
  color: #2c475a;
  position: absolute !important;
  top: 8px !important;
  right: 8px;
  font-size: 14px;
}

.nav-acc-dropdown-menu {
  left: auto !important;
  right: 0;
  top: 44px !important;
  min-width: 170px;
  border-radius: 4px;
  background: #2a4859;
  padding: 0;
  position: absolute !important;
}

.nav-acc-dropdown span {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.sidebar-menu {
  width: 70px !important;
  transform: translateX(0px) !important;
  visibility: visible;
  display: block;
  min-width: 70px;
  min-height: 100vh;
  background-color: #3d596e;
  border: 0 !important;
  position: relative;
}

.sidebar-menu.show {
  max-width: 260px;
  min-height: 100vh;
  background-color: #3d596e;
  border: 0 !important;
  position: relative;
  visibility: visible;
  display: block;
  min-width: 260px;
}

.admin-logo-name {
  display: none;
}

.sidebar-menu.show .admin-logo-name {
  display: inline-block;
}

.nav-header {
  background: #2c475a;
}

.admin-logo {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  padding: 0;
  width: 100%;
  text-align: center;
}

.cursor-poiner {
  cursor: pointer;
}

.page-content {
  padding: 10px;
  min-height: 100vh;
}

.right-content {
  width: 100%;
  margin-left: 0;
  max-width: 100%;
  overflow-x: hidden;
}

.sidebar-menu:not(.show) .sidebar-menu-item .menu-title,
.sidebar-menu:not(.show) .sidebar-submenu-item .menu-title {
  display: block;
  font-size: 10px;
  padding-left: 0 !important;
}

.sidebar-menu:not(.show) .sidebar-menu-item,
.sidebar-menu:not(.show) .sidebar-submenu-item {
  text-align: center;
  font-size: 14px;
  padding: 5px;
  margin: 0;
}

.sidebar-menu:not(.show) .sidebar-menu-item[aria-expanded="true"]::after,
.sidebar-menu:not(.show) .sidebar-menu-item[aria-expanded="false"]::after {
  right: 4px;
  font-size: 10px;
  top: 10px;
}

.sw-navbar-h {
  height: 60px;
}

.sidebar-menu-item {
  display: block;
  padding: 0.5rem 0.6rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  font-size: 14px;
  border-left: 4px solid transparent;
}

.sidebar-submenu-item {
  display: block;
  padding: 0.5rem 0.5rem;
  margin-top: 0.125rem;
  padding-left: 1.25rem;
  color: #fff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
  border-left: 4px solid transparent;
}

.sidebar-menu-item.active,
.sidebar-submenu-item.active {
  background-color: #000;
  border-left: 4px solid #fff;
  color: #fff;
}

.menu-item {
  padding-left: 4px;
}

.sidebar-menu-item[aria-expanded="false"]::after {
  content: "\f054";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  right: 14px;
}

.sidebar-menu-item[aria-expanded="true"]::after {
  content: "\f078";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  right: 14px;
}

.has-submenu {
  position: relative;
}

.sidebar-menu-item.has-submenu:hover {
  color: #fff;
}

.sidebar-menu-item:not(.has-submenu):hover,
.sidebar-submenu-item:hover {
  color: #fff;
  background-color: #2a4859;
}

.menu-title {
  padding-left: 8px;
}

.smb-icon {
  font-size: 16px;
}

.breadcrumb {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 10px;
}

.breadcrumb-item {
  text-decoration: none;
  font-size: 20px;
  color: #000;
}

.breadcrumb-item a {
  text-decoration: none;
  color: #000;
}

.filter-dev {
  margin-top: -35px;
  display: inline-block;
  background: #f5f5f5;
  width: auto;
  padding-bottom: 0px;
  border-radius: 15px;
  border: solid #a1a1a1 1px;
  margin-left: 12px;
  line-height: normal;
  padding: 8px 20px 8px 20px;
  margin-bottom: 20px;
  position: absolute;
}

.btn-default {
  background: #fff;
  border: 1px solid #ccc;
}

.btn-default:hover {
  background: #000;
  color: #fff;
  border: 1px solid #000;
}

.btn-advance-filter {
  position: relative;
  padding-left: 26px;
}

.btn-advance-filter[aria-expanded="true"]::before {
  content: "\f068";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  left: 8px;
}

.btn-advance-filter[aria-expanded="false"]::before {
  content: "\2b";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  left: 8px;
}

.sw-sort-icon {
  justify-content: flex-start;
}

.sw-sort-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-gray-600);
  height: 20px;
  width: 20px;
  cursor: pointer;
  float: right;
}

.sort-arrow {
  color: #ccc;
  margin-left: 4px;
  font-size: 12px;
}

.sort-arrow.active {
  color: #495057;
}

.th-status {
  width: 80px;
}

.th-switch {
  width: 60px;
  text-align: center;
}

.th-action {
  width: 128px;
  min-width: 128px;
}

.th-action-4 {
  width: 165px;
  min-width: 165px;
}

.th-action-1 {
  width: 50px;
  min-width: 50px;
  text-align: center;
}

.th-action-2 {
  width: 106px;
  min-width: 106px;
  text-align: center;
}

.sw-table th {
  font-size: 14px !important;
}

.sw-table tr td {
  padding: 4px 8px;
  font-size: 14px;
}

.btn-action {
  padding: 5px 10px;
  font-size: 10px;
  margin-right: 4px;
}

.sw-badge-status {
  min-width: 80px;
  font-weight: 500;
  border: 1px solid #c9c9c9;
  text-transform: uppercase;
  font-size: 10px;
}

.paginate-section {
  padding: 0px 0 30px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

/*---------paginate_button--------------*/

.paginationjs {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.paginationjs .paginationjs-pages {
  display: inline-block;
  vertical-align: top;
  float: none;
}

.paginationjs .paginationjs-pages ul {
  float: left;
  margin: 0;
  padding: 0;
}

.paginationjs .paginationjs-pages li {
  float: left;
  border: 1px solid #3d596e;
  border-right: none;
  list-style: none;
}

.paginationjs .paginationjs-pages li:first-child,
.paginationjs .paginationjs-pages li:first-child>a {
  border-radius: 3px 0 0 3px;
}

.paginationjs .paginationjs-pages li.active>a {
  height: 30px;
  background: #3d596e;
  color: #fff;
  line-height: 30px;
  font-size: 14px;
}

.paginationjs .paginationjs-pages li>a {
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  background: #fff;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  text-align: center;
}

.J-paginationjs-go-pagenumber {
  width: 40px;
  margin-left: 20px;
  border-right: 0;
  border: 1px solid #005090;
  height: 30px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
}

.J-paginationjs-go-button {
  background: #3d596e;
  color: #fff;
  border: 0;
  height: 30px;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
}

.J-paginationjs-go-pagenumber {
  width: 40px;
  margin-left: 20px;
  border-right: 0;
  border: 1px solid #3d596e;
  height: 30px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  text-align: center;
}

.J-paginationjs-go-pagenumber:focus-visible {
  outline: none;
}

.paginationjs .paginationjs-pages li:last-child {
  border-right: 1px solid #3d596e;
  border-radius: 0 3px 3px 0;
}

.grid-switch {
  min-height: auto;
  display: flex;
  justify-content: center;
  padding-left: 0;
}

.sw-nav-item-link {
  border-radius: 0 !important;
  border-right: 1px solid #ccc !important;
  color: #2a4859;
  font-weight: 600;
  background: #fff;
  border-bottom: 1px solid var(--bs-border-color-translucent) !important;
}

.sw-nav-item-link.active {
  border: 0;
  /* border-top: 3px solid #48e1e4 !important; */
  background: #3b5a6d !important;
  color: #fff !important;
  position: relative;
}

.sw-nav-content {
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
}

.sw-nav-tabs {
  background: #dee2e6;
  border: 1px solid #ccc;
  border-radius: 0;
}

.profile-icon {
  height: 25px;
  width: 25px;
  background: #2a4859;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  padding-top: 4px;
}

.btn-login {
  text-transform: uppercase;
  font-size: 15px;
}

.hr-login {
  color: #afafaf;
}

.btn-sw-primary {
  background: #2c475a;
  border: 1px solid #2c475a;
  color: #fff;
}
.btn-sw-primary:focus-visible{
  background: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-sw-primary:hover {
  background: #000;
  border: 1px solid #000;
  color: #fff;
}

.sw-link {
  color: #2c475a !important;
  text-decoration: none;
}

.sw-link:hover {
  color: #000 !important;
  text-decoration: underline;
}

.nav-acc-dropdown-menu li a {
  font-size: 14px !important;
}

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.btn-sw-secondary:hover {
  background: #000;
  border: 1px solid #000;
}

.form-check-input:checked {
  background-color: #2c475a;
  border-color: #2c475a;
}

.req-star {
  color: red;
}

.grid-switch.form-switch .form-check-input {
  width: 3em;
  margin-left: auto;
  margin-right: auto;
  height: 22px;
}

.form-switch.form-switch-md .form-check-input {
  width: 3em;

  height: 22px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #2c475a;
}

.login-title {
  text-transform: uppercase !important;
  font-weight: 600;
  color: #2a4859;
  text-align: center;
}

.lnk-forgot {
  font-size: 14px;
  font-weight: 600;
  color: #818181 !important;
}

@media (max-width: 991px) {
  .sidebar-menu {
    display: none;
  }

  .dc-sidemenu-togglebar::before {
    content: "\f00d";
    position: absolute;
    right: -60px;
    top: 0;
    height: 60px;
    width: 60px;
    background: #000;
    line-height: 60px;
    text-align: center;
    border: 1px solid #4e4e4e;
    font-size: 34px;
  }

  .sidebar-menu.show {
    position: absolute;
  }
}

.form-box-card {
  border-radius: 0px 0px 6px 6px;
  border-top: 0;
}

.grid-tab {
  background: #ffffff;
  margin-top: 0 !important;
  padding: 16px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.form-box-header {
  color: #2a4859;
  font-size: 14px;
  font-weight: 600;
  background: #f8f9fa;
  padding: 8px 8px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 0px 2px #ededed;
}

.form-box-header::before {
  content: "\f35a";
  font-family: "Font Awesome 6 Free";
  margin-right: 6px;
}

.sw-hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid #3b5a6d;
}

.form-select-field {
  border-radius: 6px;
}

.btn-check:checked+.btn {
  color: white;
  background-color: #2c475a;
  border-color: #2c475a;
}

.btn-outline-primary {
  --bs-btn-color: #2c475a;
  --bs-btn-border-color: #2c475a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c475a;
  --bs-btn-hover-border-color: #2c475a;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c475a;
  --bs-btn-active-border-color: #2c475a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #2c475a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #2c475a;
  --bs-gradient: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

hr.style-eight {
  overflow: visible;
  padding: 0;
  border: none;
  border-top: 1px solid var(--bs-gray-800);
  color: var(--bs-gray-800);
  text-align: center;
  margin: 0;
}

hr.style-eight:after {
  content: "OR";
  display: inline-block;
  position: relative;
  top: -14px;
  font-size: 14px;
  padding: 0 8px;
  background: #fff;
}

.btn-default-confirmation {
  height: 36px;
}

.form-control-search {
  background-color: #a6cc34;
}

.form-control-search:focus {
  background-color: #a6cc34;
}

.form-control-subgrid {
  width: 98px;
  height: 32px;
}

.table-nested-striped>tbody>tr:nth-child(4n)>*,
.table-nested-striped>tbody>tr:nth-child(4n-1)>* {
  background: rgb(255 255 255) !important;
  box-shadow: none;
  /* background-color: red !important; */
}

.table-striped>tbody>tr.disabled>*,
.table-striped>tbody>tr.disabled>* {
  background: #a6cc34 !important;
  box-shadow: none;
  /* background-color: red !important; */
}

.has-child-grid {
  padding: 0 !important;
}

.sw-child-grid {
  margin: 0 !important;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.05s linear;
}

.th-child,
.td-child {
  font-size: 14px !important;
  padding: 4px 8px !important;
  background: #fff;
  box-shadow: none !important;
}

.sw-nested-grid-row-control[aria-expanded="true"] {
  /* box-shadow: none !important;
  background-color: #3b5a6dc2 !important;
  color: #fff!important;
  background:#3b5a6dc2 !important; */
  font-weight: 600;
}

.sw-nested-grid-row-control[aria-expanded="true"] .fa-chevron-right:before {
  content: "\f078" !important;
  transition-property: content;
  transition-duration: 0.5s;
  font-family: "Font Awesome 6 Free";
  /* color: #fff; */
}

.sample-confirmation.form-check-input {
  height: 18px;
  width: 18px;
}

.accordian-grid-component,
.under-developemnt {
  padding: 0px;
  height: 0px;
  /* -webkit-transition: height 0.5s linear;
  -moz-transition: height 0.5s linear;
  -ms-transition: height 0.5s linear;
  -o-transition: height 0.5s linear;
  transition: height 0.5s linear; */
}

.sw-nested-grid-row.show .accordian-grid-component {
  height: 100%;
  box-shadow: none;
  width: calc(100% - 8px);
  margin: 4px;
  background: #fff;
}

.sw-nested-grid-row.show .under-developemnt {
  height: 31px;
  box-shadow: none;
  width: 1005;
  margin: 0 !important;
  background: #fff;
}

.sw-nested-grid-row.show .sw-child-grid {
  visibility: visible;
  opacity: 1;
}

.under-developemnt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 600;
  background: #3b5a6d14 !important;
}

.col-separation {
  max-width: 140px;
  /* margin: 0px 32px; */
}

.search-patient-box,
.search-barcod-box {
  padding: 8px 15px 15px 15px;
  background: #efefef;
  border-radius: 6px;
}

.css-1xc3v61-indicatorContainer {
  cursor: pointer;
}

.device-labels {
  max-width: 120px;
  display: flex;
  align-items: center;
}

.device-labels-cannister {
  max-width: 140px;
  display: flex;
  align-items: center;
}

.delete-reason-dropdown {
  margin-left: 8px;

}

.table-striped>tbody>tr.toBeDeleted>*,
.table-striped>tbody>tr.toBeDeleted>* {
  background: #dd2b2b !important;
  box-shadow: none;
  /* background-color: red !important; */
}

.grid-srno {
  width: 56px;
  min-width: 56px;
}

.date-range-section {
  flex-wrap: nowrap;
}

.date-range-section .react-datepicker-wrapper {
  max-width: fit-content;
}

.daterange-picker-input-ctrl {
  max-width: 180px;
  border-radius: 0;
}


.daterange-picker-input-ctrl.last {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.dt-input-group {
  width: 80px;
  font-size: 14px;
  justify-content: center;
}

.dt-range-calender-i {
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  bottom: 1px;
}

.iframe-image-box {
  max-width: 100%;
  max-height: 100%;
  text-align: center;
  padding: 30px;
}

.iframe-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.daterange-picker-input-ctrl-oocyte{
  max-width: 130px;
  border-radius: 0;
}

.status-log-table{
  max-width: 160px;
}
.form-control-checkbox{
  height: 20px;
  width: 20px;
}
.th-action-btn-2{
  width: 90px;
}
.form-select-subgrid{
  width: 100%;
  height: 32px;
  line-height: 16px;
  font-size: 14px;
}
.react-select-subgrid .css-13cymwt-control{
  width: 100%;
  min-height: 32px;
  max-height: 32px;
  line-height: 16px;
  font-size: 14px;
}
.react-select-subgrid  .css-1jqq78o-placeholder,.react-select-subgrid .css-tj5bde-Svg{
  position: relative;
  bottom: 2px;
}
.react-select-subgrid .css-1u9des2-indicatorSeparator{
  margin-top: 4px;
  height: 23px;
  }

.embryo-select-grid{
  width: 120px !important;
}



.td-child2 {
  font-size: 12px !important;
  padding: 4px 8px !important;
  background: #fff;
  box-shadow: none !important;
}

.trlabel{
  padding: 0px 8px!important;
}
.trlabel1{
  padding-bottom: 5px !important;
  padding-top: 0px !important;
}
.react-select-subgrid2 .css-13cymwt-control{
  width: 100%;
  min-height: 28px;
  max-height: 28px;
  line-height: 16px;
  font-size: 12px;
}
.react-select-subgrid2  .css-1jqq78o-placeholder, .react-select-subgrid2 .css-tj5bde-Svg{
  position: relative;
  bottom: 2px;
}
.react-select-subgrid2  .css-1xc3v61-indicatorContainer{
padding-top: 4px;
}
.react-select-subgrid2 .css-1u9des2-indicatorSeparator{
margin-top: 0;
height: 27px;
}
.form-control-subgrid2{
  width: 98px;
  height: 28px;
}

.embryo-select-grid2{
  width: 120px !important;
}
.multirowlabel{
  font-weight: 600 !important;
    color: #626262;
}
.actionwidth{
  width: 50px!important;
  min-width: 50px;
}
.thactionwidth{
  width: 90px!important;
  min-width: 90px;
}

.th-switch2{
  width: 140px;
}
.reportusernamecolumn{
  width: 120px;
}
.th-action-view{
  width: 100px;
  min-width: 100px;
}

.fromdate {
  width: 18%;
}
.todate {
  width: 18%;
}
.cleardate {
  width: 18%;
}
.datepickerwrapper{
  width: 28%;
}
.daterange-picker-input-ctrl::placeholder{
  font-size: 12px;
}

.updatestatusdropdown{
  max-width: 200px;
}
.th-action-credential{
  width:150px;
  min-width: 150px;
}
.th-action-samplelist{
  width:100px;
  min-width: 100px;
}
.th-action-qrcode{
  width:80px;
  min-width: 80px;
}
.th-action-resendqrcode{
  width:135px;
  min-width: 135px;
}
.table-grid-srno{
  width: 60px;
  min-width: 60px;
}
.qrcodeimg{
  width: 250px;
}
.qrcodeimgdiv{
  background-color: #2c475a;
  padding: 25px;
  width: 300px;
}
.custom-modal{
  max-width: 340px !important; 
  width: 100% !important;
}

.ltflabelwidth{
  max-width: 170px;
  width: 100% !important;
}

.Toastify__toast-container {
  width: 100%;
  top: 0;
  margin: 0;
}

.Toastify__toast {
  min-height: 52px;
}

.Toastify__toast-body {
  text-align: center;
}

.Toastify__toast--success {
  background: #3c763d;
  color: #ffffff;
}

.Toastify__toast--error {
  background: #a94442;
  color: #ffffff;
}

.Toastify__progress-bar {
  background: #ffffff;
}

.Toastify__toast-icon {
  display: none;
}

.Toastify__close-button {
  align-self: center;
  color: #ffffff;
  opacity: 1;
}

.Toastify__close-button--light {
  opacity: 1;
}

.Toastify__close-button>svg {
  fill: #ffffff;

}

.Toastify__toast-icon>svg {
  fill: #ffffff;
}

@keyframes fade-in-top {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-top {
  animation: fade-in-top 0.6s ease-out both;
}

@keyframes fade-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.fade-out-top {
  animation: fade-out-top 0.6s ease-in both;
}

.qrcode-labels {
  max-width: 130px;
  display: flex;
  align-items: center;
}
.samplecollectiondeletebtn{
  display: flex;
    justify-content: center;
    width: 50px;
}

.labelmargin{
  margin-bottom: 0rem ;
}

.labelerrormargin{
  margin-bottom: 1.5rem ;
}

@media (max-width : 767px) {
  .labelerrormargin{
    margin-bottom: 0 !important;
  }
  .scdevicepadding{
    padding-right: 0 !important;
  }

}
@media (max-width : 576px) {
  .noofdevicesfield{
    max-width: 80%;
      }
      .noofdevicebtn{
    max-width: 20%;
      }
}
